import React from "react";
import Subproject from "../../components/Subproject";

const LicensingPortal = () => {
    return (
        <Subproject
            {...{
                name: "Licensing Portal",
                technologies: "React.js, TypeScript, MUI, Symfony, Docker",
                time: "since Q1 2021",
                description:
                    "Software designed for customers using Welotec products. Web interface allows customers to view information about current orders, products, licenses and active installations. Offers option to extend or change current license. Allows browsing through multiple documentations, guides, firmwares, predefined configurations and articles depending on bought products. Includes administration panel to manage available functionalities.",
            }}
        />
    );
};

export default LicensingPortal;
