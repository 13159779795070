import React from "react";
import Subproject from "../../components/Subproject";

const InvestInLublin = () => {
    return (
        <Subproject
            {...{
                name: "Invest in Lublin",
                description:
                    "Looking for a great place to invest in Poland? Find out what our open and business-friendly city has to offer. Uncover potential of Lublin. Project developed for Lublin Municipality Office.",
                link: "https://www.invest-in-lublin.com/",
                linkName: "invest-in-lublin.com",
            }}
        />
    );
};

export default InvestInLublin;
