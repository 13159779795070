import React from "react";
import Subproject from "../../components/Subproject";

const Artunity = () => {
    return (
        <Subproject
            {...{
                name: "Artunity",
                description:
                    "Comprehensive software for artist and art business. Allows artists to share their work and participate in competitions. Place to find on-going exhibitions and job opportunities.",
                link: "https://www.artunity.eu/",
                linkName: "artunity.eu",
            }}
        />
    );
};

export default Artunity;
