import React from "react";
import Subproject from "../../components/Subproject";

const SmartEms = () => {
    return (
        <Subproject
            {...{
                name: "SMART EMS",
                technologies: "React.js, MUI, Symfony, Docker",
                time: "since Q3 2018",
                description:
                    "Central management system for automated deployment of devices. Offers easy mass management of devices using templates. Allows seamless integration with external systems via REST API. Easy to use web interface with individual branding options. Option to define access levels for users. On-demand device diagnostics for efficient management. BSI-compliant encryption and high level of security.",
                link: "https://www.welotec.com/product/welotec-smart-ems",
                linkName: "Welotec SMART EMS",
            }}
        />
    );
};

export default SmartEms;
