import React from "react";

interface ProjectProps {
    name: string;
    time: string;
    role: string;
    technologies: string;
    description: string;
    linkName?: string;
    link?: string;
}

const Project = ({ role, link, linkName, technologies, name, description, time }: ProjectProps) => {
    return (
        <div className="project__main">
            <h2 className="project__title">{name}</h2>

            <div className="project__content">
                <div className="project__subtitleGroup">
                    <h3 className="project__subtitle">
                        <span className="project__subtitleLabel">Role:</span> {role}
                    </h3>
                    <h3 className="project__subtitle">
                        <span className="project__subtitleLabel">Technologies:</span> {technologies}
                    </h3>
                    <h3 className="project__subtitle">
                        <span className="project__subtitleLabel">Time:</span> {time}
                    </h3>
                    {linkName && link && (
                        <h3 className="project__subtitle">
                            <span className="project__subtitleLabel">Link:</span>{" "}
                            <a className="project__link" href={link} target="_blank" rel="nofollow">
                                {linkName}
                            </a>
                        </h3>
                    )}
                </div>

                <p className="project__description">{description}</p>
            </div>
        </div>
    );
};

export default Project;
