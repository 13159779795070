import React from "react";
import Subproject from "../../components/Subproject";

const PolacyZmieniajaSwiat = () => {
    return (
        <Subproject
            {...{
                name: "Polacy Zmieniają Świat",
                description:
                    "Project developed under the patronage of the Ministry of State Assets, Ministry of Science and Higher Education. Software enables building a community of Polish scientists living in different parts of the world to exchange experiences and conduct joint research explorations.",
                link: "https://www.polacyzmieniajaswiat.pl",
                linkName: "polacyzmieniajaswiat.pl",
            }}
        />
    );
};

export default PolacyZmieniajaSwiat;
