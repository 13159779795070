import React from "react";
import Subproject from "../../components/Subproject";

const RetroHardwareCollection = () => {
    return (
        <Subproject
            {...{
                name: "Retro hardware collection",
                description:
                    "Website for a retro hardware collector whose long term goal is to create a great source of recordings, photos and information about it.",
                link: "https://collection.batyra.pl",
                linkName: "collection.batyra.pl",
            }}
        />
    );
};

export default RetroHardwareCollection;
