import React from "react";
import Subproject from "../../components/Subproject";

const ScepServer = () => {
    return (
        <Subproject
            {...{
                name: "SCEP Server",
                technologies: "React.js, MUI, Symfony, Docker",
                time: "since Q1 2019",
                description:
                    "PKI software. Supports SCEP. Web interface offers certificate management and individual branding options. Easy to integrate with any system using REST API.",
            }}
        />
    );
};

export default ScepServer;
