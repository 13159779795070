import React from "react";
import Subproject from "../../components/Subproject";

const ProvisioningSoftware = () => {
    return (
        <Subproject
            {...{
                name: "Provisioning software",
                technologies: "React.js, MUI, Symfony, Docker",
                time: "since Q3 2020",
                description:
                    "Variety of software designed to automate the process of provisioning devices. Provisioning stations are integrated with provisioning backend and are designed to handle a specific subset of similar devices. Provisioning backend offers configuration of provisioning devices via templates with customization of provisioning steps. Allows RMA (Return Merchandise Authorization) management and real time provisioning diagnostics.",
            }}
        />
    );
};

export default ProvisioningSoftware;
