import React from "react";
import Subproject from "../../components/Subproject";

const Velotec = () => {
    return (
        <Subproject
            {...{
                name: "Velotec",
                description:
                    "Company established by former International cyclist Padraic Quinn. Specializes in manufacturing custom cycling & triathlon wear.",
                link: "https://www.velotec.cc",
                linkName: "velotec.cc",
            }}
        />
    );
};

export default Velotec;
