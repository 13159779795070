import React from "react";
import Subproject from "../../components/Subproject";

const VpnContainerClient = () => {
    return (
        <Subproject
            {...{
                name: "VPN Container Client",
                technologies: "Python, Docker, VPN",
                time: "since Q1 2020",
                description:
                    "Hardware independent software container that opens possibility to have a fully automated VPN infrastructure. Enables connectivity and secure access to devices and third-party-machines on the field.",
            }}
        />
    );
};

export default VpnContainerClient;
