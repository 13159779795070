import React from "react";
import Subproject from "../../components/Subproject";

const VpnSecuritySuite = () => {
    return (
        <Subproject
            {...{
                name: "VPN Security Suite",
                technologies: "React.js, MUI, Symfony, Docker, VPN",
                time: "since Q4 2018",
                description:
                    "Automated rollout and management of a VPN configuration and certificates for devices. Encrypted endpoint device access. Central place for incoming VPNs, fixed IP assignments, routing and NAT. Integrated with PKI for automated management of certificates. Allows remote access to connected devices. A place for central administration with a customizable dashboard and individual branding. Option to define access levels for users.",
                link: "https://www.welotec.com/product/welotec-vpn-security-suite",
                linkName: "Welotec VPN Security Suite",
            }}
        />
    );
};

export default VpnSecuritySuite;
