import React from "react";
import Subproject from "../../components/Subproject";

const PolskiHimalaizmZimowy = () => {
    return (
        <Subproject
            {...{
                name: "Polski Himalaizm Zimowy",
                description:
                    "The program aims to conquer the mountains in the Himalayas and Karakoram on technically difficult alpine-style roads, support female Himalayan mountaineering, conquer the highest mountains in winter and explore unknown, unspoiled mountain regions.",
                link: "http://www.polskihimalaizmzimowy.pl",
                linkName: "polskihimalaizmzimowy.pl",
            }}
        />
    );
};

export default PolskiHimalaizmZimowy;
