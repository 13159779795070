import React from "react";
import Layout from "../components/Layout";
import ParkLudowy from "../projects/ParkLudowy";
import Esign from "../projects/Esign";
import ElearningPlatform from "../projects/ElearningPlatform";
import WebSolutions from "../projects/WebSolutions";
import Welotec from "../projects/Welotec";
import ButtonLink from "../components/ButtonLink";
import Seo from "../components/Seo";

const Projects = () => {
    return (
        <Layout>
            <Seo title="Projects - Arek Wtykło" />

            <h1>Projects</h1>
            <p>You can find more information about my experience and projects below.</p>

            <div className="projects__main">
                <Welotec />
                <ParkLudowy />
                <Esign />
                <ElearningPlatform />
                <WebSolutions />
            </div>

            <br />

            <div className="grid__main">
                <div className="grid__left">
                    <p className="buttonLink__text">
                        If you have more time, you can read more about me or find out how to reach me.
                    </p>

                    <ButtonLink {...{ title: "About me", to: "/about" }} />
                    <ButtonLink {...{ title: "Contact", to: "/contact" }} />
                </div>
            </div>
        </Layout>
    );
};

export default Projects;
