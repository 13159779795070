import React from "react";
import Projects from "../components/Projects";
import Artunity from "./websolutions/Artunity";
import InvestInLublin from "./websolutions/InvestInLublin";
import PolacyZmieniajaSwiat from "./websolutions/PolacyZmieniajaSwiat";
import PolskiHimalaizmZimowy from "./websolutions/PolskiHimalaizmZimowy";
import RetroHardwareCollection from "./websolutions/RetroHardwareCollection";
import Velotec from "./websolutions/Velotec";

const WebSolutions = () => {
    return (
        <Projects
            {...{
                name: "Web solutions",
                time: "2009 - 2017",
                technologies: "Bootstrap, JavaScript, Symfony, React.js",
                description:
                    "Developed over 110 projects from simple websites to robust web applications. Responsible for designing, developing and maintaining core components (CMS, administration panels, authentication), documentation and good practices. Recruited, trained, planned workstream and led a team of 5 developers (2015 - 2018).",
                projects: (
                    <>
                        <PolacyZmieniajaSwiat />
                        <Artunity />
                        <InvestInLublin />
                        <PolskiHimalaizmZimowy />
                        <RetroHardwareCollection />
                        <Velotec />
                    </>
                ),
            }}
        />
    );
};

export default WebSolutions;
