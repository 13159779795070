import React from "react";
import Projects from "../components/Projects";
import LicensingPortal from "./welotec/LicensingPortal";
import ProvisioningSoftware from "./welotec/ProvisioningSoftware";
import ScepServer from "./welotec/ScepServer";
import SmartEms from "./welotec/SmartEms";
import VpnContainerClient from "./welotec/VpnContainerClient";
import VpnSecuritySuite from "./welotec/VpnSecuritySuite";

const Welotec = () => {
    return (
        <Projects
            {...{
                name: "Welotec",
                time: "since Q3 2018",
                role: "Solution architect / full-stack developer",
                description:
                    "Work in an agile approach in multiple projects including high-level designing, prototyping, implementing, testing and code review. Cooperate with teams from various countries to integrate third party devices into Welotec systems. Responsible for application security and detecting vulnerabilities. Responsible for the full product development cycle.",
                projects: (
                    <>
                        <SmartEms />
                        <VpnSecuritySuite />
                        <ProvisioningSoftware />
                        <LicensingPortal />
                        <ScepServer />
                        <VpnContainerClient />
                    </>
                ),
            }}
        />
    );
};

export default Welotec;
