import React from "react";
import Project from "../components/Project";

const ParkLudowy = () => {
    return (
        <Project
            {...{
                name: "Park Ludowy in Lublin",
                time: "Q1 - Q2 2021",
                technologies: "React.js, TypeScript, MUI, Symfony",
                role: "Solution architect / full-stack developer",
                description:
                    "Research and implement a way to gather data from FLEXIDOME IP starlight 8000i cameras to use in smart park software (BDCS integration). Design, implement and maintain software for aggregating and visualising statistical information about visitors. Deploy solution in city server infrastructure. Prepare technical documentation. Work with external contractors from different departments as a part of 20M PLN “Park revitalization” city project. Make sure software meets WCAG 2.1 requirements. Analyze and recommend further architecture options (SaaS vs On-premises deployment).",
                link: "https://parkludowy.lublin.eu",
                linkName: "parkludowy.lublin.eu",
            }}
        />
    );
};

export default ParkLudowy;
