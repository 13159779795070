import React from "react";
import Project from "../components/Project";

const Esign = () => {
    return (
        <Project
            {...{
                name: "CRM software for eSIGN",
                time: "since Q4 2018",
                technologies: "React.js, TypeScript, MUI, Symfony",
                role: "Technical product owner / full-stack developer",
                description:
                    "Develop short-term and long-term plans for application growth to support customer service for over 30 000 clients. Responsible for identifying and implementing processes that support employees of various departments (sales, marketing, finance and management). Further improved those processes by providing automation based on integrations with third-party solutions (Google, Microsoft, Baselinker, SMS API, Zadarma, Fakturownia). Increased employee effectiveness and reduced the number of human errors by standardising product processing. Researched and modernized used technologies to increase effectiveness, efficiency and lower bug rate. Proactive monitoring and system maintenance.",
            }}
        />
    );
};

export default Esign;
