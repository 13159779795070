import React from "react";
import Project from "../components/Project";

const ElearningPlatform = () => {
    return (
        <Project
            {...{
                name: "e-learning platform",
                time: "since Q1 2017",
                technologies: "React.js, TypeScript, MUI, Symfony",
                role: "Full-stack developer",
                description:
                    "Define system boundaries based on loose client requirements. Research and prepare recommendation for architecture approach (Single-tenant SaaS, Multi-tenant SaaS, On-premise). Gather end-user needs, plan workstream and application growth to meet business needs and budget limitations. Design and implement core components and classes. Train employees using individually prepared courses with gamification mechanics. Long-term support and maintenance including proactively handling spikes in server resources usage. Meet strict security standards required by insurance companies (i.e. INTER Polska, Compensa).",
            }}
        />
    );
};

export default ElearningPlatform;
